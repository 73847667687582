import { Box } from "@caspeco/casper-ui-library.components.box";
import { Icon, Icons } from "@caspeco/casper-ui-library.components.icon";

interface IBackButton {
    onClick: () => void;
}

const BackButton: React.FC<IBackButton> = ({ onClick }) => {
    return (
        <Box
            as="button"
            onClick={onClick}
            alignItems="center"
            cursor="pointer"
            display="flex"
            flex={1}
            justifyContent="flex-start"
        >
            <Icon icon={Icons.Back}></Icon>
        </Box>
    );
};

export default BackButton;
