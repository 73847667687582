import { ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
// eslint-disable-next-line caspeco/discourage-chakra-import
import { ScaleFade, useDisclosure } from "@chakra-ui/react";
import PrepaymentEventExpiredAlert from "components/alerts/payment-event-expired-alert";
import CancelModal from "components/cancel-modal";
import ErrorBoundary from "components/error-boundary/error-boundary";
import { trackEvent } from "logging/insights";
import { Articles } from "pages/booking-details/articles";
import BookingStatus from "pages/booking-details/booking-status/booking-status";
import { BookingSummary } from "pages/booking-details/booking-summary/booking-summary";
import CancelButton from "pages/booking-details/cancel-booking/cancel-button";
import BookingActionButton from "pages/booking-details/my-booking-action-button";
import ResturantInformation from "pages/booking-details/resturant-information";
import { ErrorView } from "pages/error-view";
import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getSearchParams } from "search-params";
import {
    cancelAllowed,
    hasAvailableWaitListOffers,
    hasBookingPassed,
    hasUnhandledPaymentEventLink,
    hasWaitListOffers,
    isBookingCanceled,
    isPaymentEventExpired,
    isWaitList,
    isWaitListOfferLocked,
} from "utils/booking";
import BookMoreButton from "./book-more-button";
import MyBookingSkeleton from "./my-booking-skeleton";
import BookingOverview from "./booking-overview";
import CancelNotPossibleAlert from "./cancel-not-possible-alert";
import BookingAlert from "./my-booking-alert";
import { useCancelBooking } from "api/api-hooks/use-cancel-booking";
import { useExternalBookingSettings } from "api/api-hooks/use-external-booking-settings";
import { useWebBooking } from "api/api-hooks/use-web-booking";
import { sendTrackingEventToParent, TrackingEventType } from "utils/event-utils";
import { useBookingIdParam } from "hooks/use-booking-id-param";

export default function MyBooking() {
    const { isLoading: isLoadingBooking, data: webBooking, isError } = useWebBooking();
    const { data: settings, isPending: isLoadingSettings } = useExternalBookingSettings();
    const bookingId = useBookingIdParam();
    const {
        mutate,
        isSuccess: cancelSuccessful,
        isPending: isCancelLoading,
        isError: isCancelError,
    } = useCancelBooking();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const possibleToCancel = cancelAllowed(webBooking, settings);
    useEffect(() => {
        if (possibleToCancel) {
            const isCancel = searchParams.get("isCancel") === "true";
            const queryParams = new URLSearchParams(window.location.search);
            if (isCancel && possibleToCancel) {
                queryParams.delete("isCancel");
                const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
                navigate(newUrl, { replace: true });
                onOpen();
            }
        }
    }, [navigate, onOpen, searchParams, webBooking?.status, possibleToCancel]);

    const location = useLocation() as TypedHistory;

    if (isError) return <ErrorView />;
    if (isLoadingSettings || isLoadingBooking) return <MyBookingSkeleton />;

    const isFinal = location.state?.isFinal;

    const isCanceled = isBookingCanceled(webBooking);
    const isWaitListBooking = isWaitList(webBooking);
    const hasPassed = hasBookingPassed(webBooking);

    const isOfferLocked = isWaitListOfferLocked(webBooking);
    const hasOffers = hasWaitListOffers(webBooking);
    const hasAvailableOffers = hasAvailableWaitListOffers(webBooking);

    const onCancelBookingClick = () => {
        mutate({ id: bookingId });
        onClose();
        trackEvent("CancelBookingClicked", {
            guests: webBooking.guests,
            children: webBooking.guestsChildren,
            date: webBooking.start.toISOString(),
            isWaitingList: isWaitListBooking,
        });
        sendTrackingEventToParent({}, TrackingEventType.cancel);
    };

    const { isPaymentLink } = getSearchParams();

    const hasUnhandledPaymentLink = hasUnhandledPaymentEventLink(webBooking, isPaymentLink);
    const isPaymentLinkExpired = isPaymentEventExpired(webBooking, isPaymentLink);

    const shouldShowCancelBtn = possibleToCancel && !isFinal && !cancelSuccessful && !isOfferLocked;
    const bookingCanceled = cancelSuccessful || isCanceled;
    const shouldShowBookingSummary = (!bookingCanceled && !hasPassed && !isOfferLocked) || isFinal;
    const isBookingUnavailable =
        bookingCanceled || hasPassed || isOfferLocked || (hasOffers && !hasAvailableOffers);
    const shouldShowConfirmWaitListBtn =
        hasAvailableOffers && !isBookingUnavailable && !isOfferLocked;
    const shouldHideBoookingOverview = isOfferLocked && !hasPassed && !isFinal;
    const shouldShowActionButton =
        shouldShowConfirmWaitListBtn || (hasUnhandledPaymentLink && !isWaitListBooking);
    return (
        <ErrorBoundary fallback={<ErrorView />}>
            <ScaleFade
                className="scale-fade"
                initialScale={0.9}
                in
                transition={{ enter: { duration: 0.5 } }}
            >
                <Stack spacing={ThemeSpaceVariable.Large}>
                    <BookingStatus
                        isFinal={isFinal}
                        cancelSuccessful={cancelSuccessful}
                        isCancelError={isCancelError}
                    />
                    {!shouldHideBoookingOverview && (
                        <BookingOverview disableOverview={!isFinal && isBookingUnavailable} />
                    )}

                    {isPaymentLinkExpired && <PrepaymentEventExpiredAlert />}
                    {shouldShowActionButton && <BookingActionButton />}
                    {shouldShowBookingSummary && <BookingSummary />}
                    {webBooking?.nonTableArticles.length > 0 && <Articles />}

                    <BookingAlert
                        shouldShowConfirmWaitListBtn={shouldShowConfirmWaitListBtn}
                        isBookingCanceled={bookingCanceled}
                    />

                    {shouldShowCancelBtn ? (
                        <CancelButton onClick={onOpen} isLoading={isCancelLoading} />
                    ) : !isFinal && !isBookingUnavailable && !isPaymentLinkExpired ? (
                        <CancelNotPossibleAlert />
                    ) : (
                        <BookMoreButton />
                    )}

                    <ResturantInformation />
                </Stack>
                <CancelModal
                    isOpen={isOpen}
                    handleCancelModalModalClose={onClose}
                    handleCancelModalCancel={onCancelBookingClick}
                />
            </ScaleFade>
        </ErrorBoundary>
    );
}
