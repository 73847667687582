import { Box } from "@caspeco/casper-ui-library.components.box";
import { useDayStates } from "api/api-hooks/use-day-states";
import { useExternalBookingSettings } from "api/api-hooks/use-external-booking-settings";
import DetailsFormWrapper from "components/details-form/details-form-wrapper";
import ErrorBoundary from "components/error-boundary/error-boundary";
import ReservationFailedModal from "components/error-components/reservation-failed-modal";
import AvailabilityView from "components/selection-components/availability-view/availability-view";
import { SelectChildrenAmount } from "components/selection-components/select-children";
import { SelectDate } from "components/selection-components/select-date/select-date";
import { SelectGuestsAmount } from "components/selection-components/select-guests";
import Spinner from "components/spinner/spinner";
import { Stepper } from "components/stepper/stepper";
import "global.less";
import { useBookingStateContext } from "hooks/use-booking-state";
import { ErrorView } from "pages/error-view";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSearchParams } from "search-params";
import { IsoCountryCode } from "types/iso-country-code";

const isInitialized = false;
function init() {
    if (isInitialized) return;

    const { system } = getSearchParams();

    const systemParts = system?.split("_");
    const [SYSTEM_PREFIX] = systemParts ?? [];

    if (
        SYSTEM_PREFIX &&
        !Object.values(IsoCountryCode).includes(SYSTEM_PREFIX.toLocaleUpperCase())
    ) {
        // Antiviruses scanning emails, ROT-13 encrypts system- and other GET-parameters resulting in spammy requests to MARC
        throw new Error(`Invalid system prefix in: ${system}`);
    }
}
init();
export default function BookingWizard() {
    const externalBookingSettings = useExternalBookingSettings();
    const { system, unitId } = useParams();
    const [selectedMonth, setSelectedMonth] = useState(new Date());

    useDayStates(selectedMonth);

    useEffect(() => {
        if (system && unitId) {
            localStorage.setItem("lastKnownSystemId", system);
            localStorage.setItem("lastKnownUnitId", unitId);
        }
    }, [system, unitId]);

    const { guests, guestsChildren, date, times } = useBookingStateContext();

    if (externalBookingSettings.isLoading) {
        return <Spinner />;
    }

    if (externalBookingSettings.isError) {
        return <ErrorView error={externalBookingSettings.error} />;
    }
    const { activateWebChildren, maxWebGuests } = externalBookingSettings.data.unitsMetaData;

    return (
        <ErrorBoundary fallback={<ErrorView />}>
            <Box className="webBookingContainer" flex={1}>
                <ReservationFailedModal />
                <Stepper />
                {!guests || guests > maxWebGuests ? (
                    <SelectGuestsAmount />
                ) : activateWebChildren && guestsChildren === null ? (
                    <SelectChildrenAmount />
                ) : !date ? (
                    <SelectDate selectedMonth={selectedMonth} setSelectedMonth={setSelectedMonth} />
                ) : !times ? (
                    <AvailabilityView />
                ) : (
                    <DetailsFormWrapper />
                )}
            </Box>
        </ErrorBoundary>
    );
}
