import SomethingWrong from "components/error-components/something-wrong";
import SelectionHeader from "components/selection-components/selection-header";
import { useTranslation } from "react-i18next";
import ActivityItem from "./activity-item";
import ActivityModal from "components/selection-components/select-activity/activity-modal";
import { useState } from "react";
import { AvailableTimesSection } from "types/available-times";
import ActivityListSkeleton from "components/selection-components/select-activity/activity-list-skeleton";
import { useAvailableTimes, useFilteredAvailableTimes } from "api/api-hooks/use-available-times";
import { useBookingStateContext } from "hooks/use-booking-state";

const SelectActivity = () => {
    const { t } = useTranslation();
    const { date } = useBookingStateContext();
    const [openActivity, setOpenActivity] = useState<AvailableTimesSection | null>(null);

    const availableTimes = useAvailableTimes(date);
    const availableSections = useFilteredAvailableTimes(date);

    if (availableTimes.isLoading) {
        return <ActivityListSkeleton />;
    }
    if (availableTimes.isError) {
        return <SomethingWrong />;
    }

    return (
        <>
            <SelectionHeader
                id="timeChoiceHeader"
                key="timeChoiseHeader"
                text={t("selectWhatToBook")}
            />
            {availableSections.map((section) => (
                <ActivityItem
                    key={section.id}
                    section={section}
                    onClick={() => setOpenActivity(section)}
                />
            ))}
            <ActivityModal section={openActivity} onClose={() => setOpenActivity(null)} />
        </>
    );
};

export default SelectActivity;
