import "./change-url";
import { asBool, asDate, asInt, asIntArray, asString } from "./parse";

export type UrlParams = {
    date: moment.Moment;
    guestsChildren: number;
    sectionIds: number[];
    system: string;
    tag: string;
    webBookingId: string;
    guests: number;
    unitId: number;
    start: string;
    end: string;
    ruleId: number;
    chargeId: string;
    offerStartTime: string;
    isPaymentLink: boolean;
    captured: boolean;
    isNoShow: boolean;
    isActivityBooking: boolean;
};

let lastUrl = "";
let cache: UrlParams;
// eslint-disable-next-line prefer-const
export let getSearchParams = () => {
    if (window.location.href !== lastUrl) {
        lastUrl = window.location.href;
        cache = {
            system: asString("system", null),
            unitId: asInt("unitId"),
            sectionIds: asIntArray("sectionIds"),
            tag: asString("tag", null),
            // Query Strings
            webBookingId: asString("webBookingId", null),
            start: asString("start"),
            end: asString("end"),
            // Date moment
            date: asDate("date"),
            // Query Number
            ruleId: asInt("r"),
            guests: asInt("guests"),
            guestsChildren: asInt("children"),
            chargeId: asString("ChargeId", null),
            offerStartTime: asString("offerStartTime", null),
            isPaymentLink: asBool("paymentLink"),
            captured: asBool("Captured"),
            isNoShow: asBool("isNoShow"),
            isActivityBooking: asBool("activityBooking"),
        };
    }
    return cache;
};
