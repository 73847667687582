import { State } from "store/store-types";

export function getStepperProgressPercentage(state: State, activateWebChildren: boolean) {
    const { guests, guestsChildren, date, times } = state;
    const allSteps = [
        {
            condition: guests && (!activateWebChildren || guestsChildren !== null),
        },
        {
            condition: date,
        },
        {
            condition: times,
        },
    ];

    const totalSteps = allSteps.length;
    const completedSteps = allSteps.reduce((completedSteps, item) => {
        const isConditionMet = item.condition;
        if (isConditionMet) return completedSteps + 1;
        return completedSteps;
    }, 0);

    const maxPercentage = 100;
    const stepPercentageValue = maxPercentage / totalSteps;
    return stepPercentageValue * completedSteps;
}
