import { PaymentRule, PaymentCalculationRule } from "types/payment";
import { TimeSlot } from "types/time-slot";
import { parseDate } from "utils/moment-utils";

export class WebRule {
    id: number;
    title: string;

    conditions: string;
    paymentInformation: string;
    maxGroupSize: number;
    minGroupSize: number;
    lengthMinutes: number;
    recoupTime: number;
    isClosed: boolean;
    date: IMoment;
    arenaEventId: number;
    arenaCrmGroupId: number;
    times: TimeSlot[];
    menuGuid: string;
    requireMenuSelection: boolean;
    requireMenuPayment: boolean;

    startTime: number;
    endTime: number;
    lockTime: number;
    lockPerTime: boolean;

    /* Duplicates to map from backend */
    readonly payment: PaymentRule;
    readonly paymentModifier: PaymentCalculationRule;
    /* Frontend mapped named */
    paymentRule: PaymentRule;
    paymentCalculationRule: PaymentCalculationRule;

    paymentAmount: number;
    paymentVAT: number;
    isTextRow: boolean;
    comment: string;
    sectionId: number;
    timeZone: string;

    constructor(values?: Partial<WebRule>) {
        this.id = values?.id ?? 0;
        this.title = values?.title ?? "";
        this.conditions = values?.conditions ?? "";
        this.paymentInformation = values?.paymentInformation ?? "";
        this.maxGroupSize = values?.maxGroupSize ?? 0;
        this.minGroupSize = values?.minGroupSize ?? 0;
        this.recoupTime = values?.recoupTime ?? 0;
        this.isClosed = values?.isClosed ?? false;
        this.date = parseDate(values?.date);
        this.arenaEventId = values?.arenaEventId ?? 0;
        this.arenaCrmGroupId = values?.arenaCrmGroupId ?? 0;
        this.times = values?.times ?? [];
        this.menuGuid = values?.menuGuid ?? "";
        this.requireMenuSelection = values?.requireMenuSelection ?? false;
        this.requireMenuPayment = values?.requireMenuPayment ?? false;
        this.paymentRule = values?.payment ?? PaymentRule.NoFee;
        this.paymentCalculationRule = values?.paymentModifier ?? PaymentCalculationRule.NoPayment;
        this.paymentAmount = values?.paymentAmount ?? 0;
        this.paymentVAT = values?.paymentVAT ?? 0;
        this.isTextRow = values?.isTextRow ?? false;
        this.comment = values?.comment ?? "";
        this.sectionId = values?.sectionId ?? 0;
        this.timeZone = values?.timeZone ?? "";
    }

    public hasAnyPaymentRule(): boolean {
        return (
            this.paymentRule === PaymentRule.Prepayment ||
            this.paymentRule === PaymentRule.NoShowFee
        );
    }

    public hasNoShowRule(): boolean {
        return this.paymentRule === PaymentRule.NoShowFee;
    }

    public hasPrepaymentRule(): boolean {
        return this.paymentRule === PaymentRule.Prepayment;
    }
}
