import { ThemeFontSizeVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Text } from "@caspeco/casper-ui-library.components.text";
import { BookingSuccess } from "pages/booking-details/booking-status/booking-success";
import { CancelBookingFailed } from "pages/booking-details/cancel-booking/cancel-booking-failed";
import { CancelBookingSuccess } from "pages/booking-details/cancel-booking/cancel-booking-success";
import Greeting from "pages/booking-details/cancel-booking/greeting";
import React from "react";
import { useTranslation } from "react-i18next";
import { hasBookingPassed, hasWaitListOffers, isBookingCanceled, isWaitList } from "utils/booking";
import GreetingWrapper from "./greeting-wrapper";
import WaitListOffer from "./wait-list-offer";
import { WaitListSuccess } from "./wait-list-success";
import { useWebBooking } from "api/api-hooks/use-web-booking";

interface BookingStatusProps {
    isFinal: boolean;
    cancelSuccessful: boolean;
    isCancelError: boolean;
}

const BookingStatus: React.FC<BookingStatusProps> = ({
    isFinal,
    cancelSuccessful,
    isCancelError,
}) => {
    const webBooking = useWebBooking().data;
    const { t } = useTranslation();
    const guests = webBooking?.guests;
    const date = webBooking?.start.format("dddd D MMMM");
    const startTime = webBooking?.start.format("HH:mm");
    const endTime = webBooking?.end.format("HH:mm");

    const hasCanceledStatus = isBookingCanceled(webBooking);
    const isWaitListBooking = isWaitList(webBooking);
    const hasPassed = hasBookingPassed(webBooking);

    if (isFinal && isWaitListBooking) {
        return <WaitListSuccess />;
    }
    if (isFinal && !cancelSuccessful) {
        return <BookingSuccess />;
    }
    if (cancelSuccessful) {
        return <CancelBookingSuccess isWaitList={isWaitListBooking} />;
    }
    if (hasCanceledStatus && isWaitListBooking) {
        return (
            <GreetingWrapper name={webBooking?.contact.fname}>
                <Text fontSize={ThemeFontSizeVariable.Large}>
                    {t("waitList.waitListIsCanceled")}
                </Text>
            </GreetingWrapper>
        );
    }
    if (hasCanceledStatus) {
        return (
            <GreetingWrapper name={webBooking?.contact.fname}>
                <Text fontSize={ThemeFontSizeVariable.Large}>
                    {t("bookingIsCanceled", { guests, date, startTime, endTime })}
                </Text>
            </GreetingWrapper>
        );
    }
    if (hasPassed) {
        return (
            <GreetingWrapper name={webBooking?.contact.fname}>
                <Text fontSize={ThemeFontSizeVariable.Large}>
                    {t("theTimeHasPassed", { date })}
                </Text>
            </GreetingWrapper>
        );
    }
    if (hasWaitListOffers(webBooking)) {
        return <WaitListOffer />;
    }
    if (isCancelError) {
        return <CancelBookingFailed />;
    }
    return <Greeting name={webBooking?.contact.fname} isWaitList={isWaitListBooking} />;
};

export default BookingStatus;
