import { useCancelBooking } from "api/api-hooks/use-cancel-booking";
import { useCreateReservation } from "api/api-hooks/use-create-reservation";
import { useFinalizeBooking } from "api/api-hooks/use-finalize-booking";
import { LayoutContext } from "layout/layout-context";
import { trackEvent } from "logging/insights";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { getSearchParams } from "search-params";
import { setSearch } from "search-params/parse";
import { BookingSetStateContext, BookingStateContext } from "store/store-context";
import { getStateFromParams } from "store/store-helper";
import { State } from "store/store-types";
import { Contact } from "types/contact";
import { Customer } from "types/customer";
import { PaymentMethod } from "types/payment";
import { sendTrackingEventToParent } from "utils/event-utils";

export const BookingProvider = () => {
    const params = getSearchParams();
    const navigate = useNavigate();
    const layoutContext = useContext(LayoutContext);
    const { mutate: cancelBooking } = useCancelBooking();
    const reservation = useCreateReservation();
    const finalize = useFinalizeBooking();
    const [state, setState] = useState(getStateFromParams(params, {}));

    useEffect(() => {
        setState(getStateFromParams(params, state));
    }, [params, state]);

    useEffect(() => {
        if (params.guests) layoutContext.setShowCoverImage(false);
    }, [layoutContext, params.guests]);

    useEffect(() => {
        sendTrackingEventToParent(state);
    }, [state]);

    const { reset } = reservation;
    const handleCancel = () => cancelBooking({ id: undefined, callback: reset });

    const setPaymentMethod = (method: PaymentMethod) => {
        setState((prevState) => ({
            ...prevState,
            payment: {
                payMethod: method,
            },
        }));
    };

    const setIsWaitList = (isWaitList: boolean) => {
        setState((prevState) => ({
            ...prevState,
            isWaitList: isWaitList,
        }));
    };

    const setGuests = (guests: number) => {
        trackEvent("GuestsSelected", {
            count: guests,
        });
        setState((prevState) => ({
            ...prevState,
            guests,
        }));
        if (params.guests !== guests) {
            setSearch(navigate, "guests", guests.toString());
        }
    };

    const setChildren = (guestsChildren: number) => {
        trackEvent("SelectChildrenAmount", {
            guestsChildren,
            children: guestsChildren,
        });
        setState((prevState) => ({
            ...prevState,
            guestsChildren,
        }));
        if (params.guests !== guestsChildren) {
            setSearch(navigate, "children", guestsChildren.toString());
        }
    };

    const setDate = (date: Date) => {
        setState((prevState) => ({
            ...prevState,
            date: moment(date),
        }));
        const urlDate = moment(date).format("YYYY-MM-DD");
        if (params.date === null || params.date.format("YYYY-MM-DD") !== urlDate)
            setSearch(navigate, "date", urlDate);
    };

    const setContact = (contact: Partial<Contact>) => {
        setState((prevState) => ({
            ...prevState,
            contact: {
                ...prevState.contact,
                ...contact,
            },
        }));
    };
    const setCustomer = (customer: Customer) => {
        setState((prevState) => ({
            ...prevState,
            customer: {
                ...prevState.customer,
                ...customer,
            },
        }));
    };

    const handleReservation = (state: State) => {
        window.scrollTo({ behavior: "smooth", top: 0, left: 0 });
        window.parent.postMessage("scroll2top", "*");
        setState(state);
        const r = state.times[0].ruleId.toString();
        const start = state.times.map((t) => t.start.format("HHmm")).join("-");
        const end = state.times.length > 1 ? "" : state.times[0].end.format("HHmm");
        setSearch(navigate, { r, start, end });
        reservation.mutate(state);
    };

    const handleFinalize = () => {
        finalize.mutate(state);
    };

    const storeActions = {
        setState,
        setIsWaitList,
        setPaymentMethod,
        setGuests,
        setChildren,
        setDate,
        setContact,
        setCustomer,
        handleReservation,
        handleFinalize,
        handleCancel,
        finalize,
        reservation,
    };
    return (
        <BookingStateContext.Provider value={state}>
            <BookingSetStateContext.Provider value={storeActions}>
                <Outlet />
            </BookingSetStateContext.Provider>
        </BookingStateContext.Provider>
    );
};
