import React from "react";
import { Divider } from "@caspeco/casper-ui-library.components.divider";
import { Box } from "@caspeco/casper-ui-library.components.box";
import { Image } from "@caspeco/casper-ui-library.components.image";
import {
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeFontVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
import { Text } from "@caspeco/casper-ui-library.components.text";
import { Flex } from "@caspeco/casper-ui-library.components.flex";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import { AvailableTimesSection } from "types/available-times";
import ActivityBadges from "components/selection-components/select-activity/activity-badges/activity-badges";

interface IActivityItem {
    section: AvailableTimesSection;
    onClick: () => void;
}

const tempImageSrc =
    "https://imagecdn.caspeco.net/images/1c30359b-cfd7-40b0-bc8e-59c4a9b7f8a6?AspectRatio=ONE_BY_ONE&Type=webp";
const activityItemDesc = "2 karaokegolv med olika teman";

const ActivityItem = ({ section, onClick }: IActivityItem) => {
    return (
        <Box onClick={onClick} cursor="pointer">
            <Flex
                justify="space-between"
                py={ThemeSpaceVariable.Medium}
                align="center"
                gap={ThemeSpaceVariable.Small}
            >
                <Stack spacing={ThemeSpaceVariable.None}>
                    <Heading
                        as="h2"
                        noOfLines={1}
                        fontFamily={ThemeFontVariable.SubHeader}
                        fontWeight="medium"
                        color={ThemeColorVariable.OnBackgroundHeader}
                        fontSize={{
                            base: ThemeFontSizeVariable.Large,
                            md: ThemeFontSizeVariable.XLarge,
                        }}
                    >
                        {section.name}
                    </Heading>
                    <Text
                        height={{
                            base: ThemeFontSizeVariable.XLarge,
                            md: "unset",
                        }}
                        noOfLines={1}
                        whiteSpace="break-spaces"
                        fontSize={{
                            base: ThemeFontSizeVariable.Small,
                            md: ThemeFontSizeVariable.Large,
                        }}
                    >
                        {activityItemDesc}
                    </Text>

                    <ActivityBadges section={section} />
                </Stack>
                {tempImageSrc && (
                    <Image src={tempImageSrc} maxH="72px" borderRadius={ThemeSpaceVariable.Small} />
                )}
            </Flex>
            <Divider />
        </Box>
    );
};

export default ActivityItem;
