import {
    ThemeFontSizeVariable,
    ThemeFontVariable,
    ThemeRadiusVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Button } from "@caspeco/casper-ui-library.components.button";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import { Image } from "@caspeco/casper-ui-library.components.image";
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    ModalHeader,
} from "@caspeco/casper-ui-library.components.modal";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
import { Text } from "@caspeco/casper-ui-library.components.text";
import { useCreateReservation, useUpdateReservation } from "api/api-hooks/use-create-reservation";
import ActivityBadges from "components/selection-components/select-activity/activity-badges/activity-badges";
import ActivityTimeSlot from "components/selection-components/select-activity/activity-time-slot";
import TimeSetAccordion from "components/selection-components/select-activity/time-set-accordion";
import { default as TimeSlotGrid } from "components/time-slot-grid";
import { useBookingStateContext } from "hooks/use-booking-state";
import { t } from "i18next";
import { useState } from "react";
import { AvailableTimesSection } from "types/available-times";
import { TimeSlot } from "types/time-slot";
import { saveSectionNameAndWebRuleTitle } from "utils/event-utils";
import { mergeTimeSetsByTitle } from "utils/time-rule-utils";

interface IActivityModal {
    section: AvailableTimesSection;
    onClose: () => void;
}

const desc =
    "Ett spel är 55 minuter och för att ni ska få en så bra upplevelse som möjligt kan ni spela 1-6 personer per bana. Är ni fler... Visa mer";
const tempImg =
    "https://s3-alpha-sig.figma.com/img/ff68/fcab/8454821534dec35d55edffbc1bf68424?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Mt6htJ-TXZ~KIVoPoJ67CHntbRSCwRBEKSXzC2sS7drEEL46l2DIYOwHwiVfpIz4qCqMRnLCeS1sMU5i5D8tue44bbo-Guk818Jn6Zyk-ITTrWabHgegsJu-HEQ-dM-nZNxdGEReAcJb~Su8iAdoN~yGwnaEkkTBITCGatmkizHbMT17kgX2W~Cv4BKo5c8-tvoNBAkLuK9aIBoStumjAQJSjlhM53oUXcmZrnlVO0wgv-Hs69lJjZnp55jRHUNI9W8fWP2TiMcg2mFwpluMf1wQtgri21TkOWvEHXk7FtJrMvOtJwLu1Q17vCEMMgc2Rl0hjuITdV6wnEU2R0jjjA__";

const ActivityModal = ({ section, onClose }: IActivityModal) => {
    const createBooking = useCreateReservation();
    const updateBooking = useUpdateReservation();
    const state = useBookingStateContext();
    const [selectedTime, setSelectedTime] = useState<TimeSlot>();

    if (!section) return null;

    const timeSets = section.timeSets.filter((x) => !x.isTextRow && !x.isClosed);

    const mergedTimeSets = mergeTimeSetsByTitle(timeSets);
    const useAccordion = mergedTimeSets.length > 1;

    const handleSelectActivity = async () => {
        const newTime = {
            ruleId: selectedTime.ruleId,
            start: selectedTime.start,
            end: selectedTime.end,
        };
        saveSectionNameAndWebRuleTitle(selectedTime.name, selectedTime.title);
        const times = state.times ? [...state.times, newTime] : [newTime];
        const stateWithTimes = {
            ...state,
            times,
        };

        if (createBooking.isSuccess || createBooking.isPending) {
            await updateBooking.mutateAsync(stateWithTimes);
        } else {
            await createBooking.mutateAsync(stateWithTimes);
        }

        handleOnClose();
    };

    const handleOnClose = () => {
        onClose();
        setSelectedTime(undefined);
    };

    return (
        <Modal isOpen={Boolean(section)} onClose={handleOnClose} size="md">
            <ModalBody display="flex" flexDirection="column">
                <ModalCloseButton isAbsolutePositioned />
                <ModalHeader overflow="hidden">
                    <Image borderTopRadius={ThemeRadiusVariable.Medium} src={tempImg} />
                    {/* // TODO: Reinstate AspectRatio? */}
                    {/* <AspectRatio maxW="100%">
                    </AspectRatio> */}
                </ModalHeader>

                <Stack>
                    <Stack spacing={ThemeSpaceVariable.Small} p={ThemeSpaceVariable.Medium}>
                        <Heading
                            as="h2"
                            fontWeight="medium"
                            fontSize={ThemeFontSizeVariable.XLarge}
                            fontFamily={ThemeFontVariable.SubHeader}
                        >
                            {section.name}
                        </Heading>
                        <Text fontWeight="medium">{desc}</Text>
                    </Stack>

                    <Stack spacing={ThemeSpaceVariable.Small} p={ThemeSpaceVariable.Medium}>
                        <Text fontWeight="medium">{t("moreInfo")}</Text>
                        <ActivityBadges section={section} />
                    </Stack>

                    <Stack p={ThemeSpaceVariable.Medium} spacing={ThemeSpaceVariable.Medium}>
                        <Heading as="h1" fontSize={ThemeFontSizeVariable.XLarge}>
                            {t("selectTime")}
                        </Heading>

                        <>
                            {timeSets.map((timeSet) => {
                                if (useAccordion) {
                                    return (
                                        <TimeSetAccordion
                                            key={`time-set-${timeSet.id}`}
                                            timeSet={timeSet}
                                            selectedTime={selectedTime}
                                            onClick={setSelectedTime}
                                        />
                                    );
                                }
                                return (
                                    <TimeSlotGrid
                                        key={`timeSet${timeSet.id}`}
                                        items={timeSet.times.map((time) => (
                                            <ActivityTimeSlot
                                                key={time.start.format("HH:mm")}
                                                time={time}
                                                selectedTime={selectedTime}
                                                onClick={() => setSelectedTime(time)}
                                            />
                                        ))}
                                    />
                                );
                            })}
                        </>
                    </Stack>
                </Stack>
            </ModalBody>

            <ModalFooter
                p={ThemeSpaceVariable.Medium}
                display="flex"
                justifyContent="flex-end"
                zIndex={1}
            >
                <Button
                    variant="primary"
                    size={["lg", "md"]}
                    alignSelf="flex-end"
                    w="full"
                    onClick={() => void handleSelectActivity()}
                    isLoading={createBooking.isPending || updateBooking.isPending}
                >
                    {!selectedTime
                        ? t("addWithEllipsis")
                        : t("continueWithTimeX", { time: selectedTime.start.format("HH:mm") })}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ActivityModal;
