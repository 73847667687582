import {
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeRadiusVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Box } from "@caspeco/casper-ui-library.components.box";
import { Flex } from "@caspeco/casper-ui-library.components.flex";
import { Text } from "@caspeco/casper-ui-library.components.text";
import { useExternalBookingSettings } from "api/api-hooks/use-external-booking-settings";
import BackButton from "components/stepper/back-button";
import { getStepperProgressPercentage } from "components/stepper/stepper-helper";
import { ActiveStep, useActiveStep } from "components/stepper/use-active-step";
import { useBookingActionsContext, useBookingStateContext } from "hooks/use-booking-state";
import { useSelectedRule } from "hooks/use-selected-rule";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getSearchParams } from "search-params";
import { setSearch } from "search-params/parse";

export const Stepper = () => {
    const { activateWebChildren } = useExternalBookingSettings().data.unitsMetaData;
    const { setState, handleCancel } = useBookingActionsContext();
    const state = useBookingStateContext();
    const selectedRule = useSelectedRule();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const activeStep = useActiveStep();

    const setMultipleSearch = (keys: string[]) => {
        keys.forEach((key) => setSearch(navigate, key));
    };

    const { guests, date, times } = state;
    const resetLastStep = () => {
        if (times?.length > 0) handleCancel();

        const newState = state;
        newState.isWaitList = false;

        let resetSerachParmas: string[];
        switch (activeStep) {
            case ActiveStep.GuestsChildren:
                newState.guests = null;
                resetSerachParmas = ["guests"];
                break;
            case ActiveStep.Date:
                if (activateWebChildren) {
                    newState.guestsChildren = null;
                    resetSerachParmas = ["children"];
                } else {
                    newState.guests = null;
                    resetSerachParmas = ["guests"];
                }
                break;
            case ActiveStep.Times:
                newState.date = null;
                resetSerachParmas = ["date"];
                break;
            default:
                newState.times = null;
                resetSerachParmas = ["r", "start", "end"];
                break;
        }

        setMultipleSearch(resetSerachParmas);
        setState(newState);
    };

    const { isActivityBooking } = getSearchParams();
    const dateText = date?.format("D MMM YYYY");
    const guestText = t("start.numberGuests", { guests });
    const stepContext = [
        {
            condition: ActiveStep.Guests,
            summaryText: t("stepper.welcome"),
            stepTitle: t("stepper.chooseGuestSize"),
            hideBackButton: true,
        },
        {
            condition: ActiveStep.GuestsChildren,
            summaryText: guestText,
            stepTitle: t("stepper.chooseGuestSize"),
        },
        {
            condition: ActiveStep.Date,
            summaryText: guestText,
            stepTitle: t("stepper.chooseDate"),
        },

        {
            condition: ActiveStep.Times,
            summaryText: `${guestText}, ${dateText}`,
            stepTitle: isActivityBooking ? t("stepper.chooseActivities") : t("stepper.chooseTime"),
        },
        {
            condition: ActiveStep.Details,
            summaryText: `${guestText}, ${dateText}`,
            stepTitle: selectedRule?.hasPrepaymentRule()
                ? t("stepper.confirmDetailsAndPayment")
                : t("stepper.confirmDetails"),
        },
    ];

    const progress = getStepperProgressPercentage(state, activateWebChildren);
    return (
        <Flex flexDirection="column">
            <Flex
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
            >
                <Box
                    flex={1}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    gap={ThemeSpaceVariable.XSmall}
                    fontSize={ThemeFontSizeVariable.Medium}
                >
                    {stepContext.map((step) => {
                        if (step.condition === activeStep) {
                            return (
                                <Flex key={step.condition} w="full">
                                    {step.hideBackButton ? (
                                        <Flex flex={1} justifyContent="flex-start" />
                                    ) : (
                                        <BackButton onClick={resetLastStep} />
                                    )}
                                    <Flex
                                        direction="column"
                                        align="center"
                                        gap={ThemeSpaceVariable.XSmall}
                                        flex={3}
                                    >
                                        <Text
                                            as="span"
                                            fontSize={ThemeFontSizeVariable.Small}
                                            color={ThemeColorVariable.OnBackgroundSubdued}
                                        >
                                            {step.summaryText}
                                        </Text>
                                        <Text fontWeight="bold">{step.stepTitle}</Text>
                                    </Flex>
                                    <Flex flex="1" />
                                </Flex>
                            );
                        }
                    })}
                </Box>
            </Flex>

            <Box>
                <Box
                    position="relative"
                    width="100%"
                    height={["4px", "6px", "8px", "10px"]}
                    mt={{ base: "4px", sm: "8px", md: "12px" }}
                    bg={ThemeColorVariable.OnSurfaceBorder}
                    borderRadius={ThemeRadiusVariable.Small}
                    overflow="hidden"
                >
                    <Box
                        width={`${progress}%`}
                        height="100%"
                        background={ThemeColorVariable.Success}
                    />
                </Box>
            </Box>
        </Flex>
    );
};
