import SelectActivity from "components/selection-components/select-activity/select-activity";
import { SelectTime } from "components/selection-components/select-time/select-time";
import React from "react";
import { getSearchParams } from "search-params";

const AvailabilityView = () => {
    const { isActivityBooking } = getSearchParams();

    return <>{isActivityBooking ? <SelectActivity /> : <SelectTime />}</>;
};

export default AvailabilityView;
