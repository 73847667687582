import { ThemeColorVariable, ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Box } from "@caspeco/casper-ui-library.components.box";
import { Flex } from "@caspeco/casper-ui-library.components.flex";
import { ToggleButton } from "@caspeco/casper-ui-library.components.toggle-button";
// eslint-disable-next-line caspeco/discourage-chakra-import
import { ScaleFade } from "@chakra-ui/react";
import { TimeSlot } from "types/time-slot";

interface WaitListTimeSlotProps {
    time: TimeSlot;
    onTimeClick: (time: TimeSlot) => void;
    selectedTimes: TimeSlot[];
    isWaitListModal?: boolean;
}

export default function WaitListTimeSlot({
    onTimeClick,
    time,
    selectedTimes,
    isWaitListModal,
}: WaitListTimeSlotProps) {
    const isSelected = selectedTimes.indexOf(time) !== -1;
    return (
        <ScaleFade
            initialScale={isWaitListModal ? 1 : 0.9}
            in={true}
            transition={{ enter: { duration: 0.5 } }}
        >
            <ToggleButton
                key={time.start.format("HH:mm")}
                onClick={() => onTimeClick(time)}
                isPressed={isSelected}
                w="full"
                size="lg"
                minW="none"
                px={ThemeSpaceVariable.Small}
            >
                <Flex direction="column" align="center">
                    <Box mt="6px">{time.start.format("HH:mm")}</Box>
                    <Box
                        as="span"
                        w="6px"
                        h="6px"
                        background={ThemeColorVariable.Warning}
                        borderRadius="full"
                    />
                </Flex>
            </ToggleButton>
        </ScaleFade>
    );
}
