import {
    ThemeBorderVariable,
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeRadiusVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Box } from "@caspeco/casper-ui-library.components.box";
import { Divider } from "@caspeco/casper-ui-library.components.divider";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
import { Text } from "@caspeco/casper-ui-library.components.text";
import { useWebBooking } from "api/api-hooks/use-web-booking";
import Price from "components/price";
import { useCurrencyCode } from "hooks/use-currency-code";
import { SpaceBetween } from "layout/space-between";
import { useTranslation } from "react-i18next";

export function Articles() {
    const { t } = useTranslation();
    const { data: webBooking } = useWebBooking();
    const { nonTableArticles } = webBooking;

    const border = `${ThemeBorderVariable.XSmall} ${ThemeColorVariable.OnSurfaceBorder}`;
    const currency = useCurrencyCode();
    return (
        <Stack>
            <Heading as="h4" fontWeight={"medium"} fontSize={ThemeFontSizeVariable.Large}>
                {t("menuSummary.choosenMenu")}
            </Heading>
            <Stack
                border={border}
                borderRadius={ThemeRadiusVariable.Small}
                p={ThemeSpaceVariable.Medium}
                background={ThemeColorVariable.Surface}
            >
                {nonTableArticles.map((article) => (
                    <SpaceBetween key={article.articleId}>
                        <Text>{article.name}</Text>
                        <Price amount={article.outPriceIncVat} />
                    </SpaceBetween>
                ))}
                <Divider />
                <SpaceBetween>
                    <Box>
                        <Text as="span" fontWeight="bold">
                            {`${t("menuSummary.totalCost")} ${currency} `}
                        </Text>
                        <Text as="span" color={ThemeColorVariable.OnSurfaceSubdued}>
                            {t("menuSummary.inkVat")}
                        </Text>
                    </Box>
                    <Price
                        amount={nonTableArticles.reduce((sum, a) => sum + a.outPriceIncVat, 0)}
                    />
                </SpaceBetween>
            </Stack>
        </Stack>
    );
}
