import { ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { ToggleButton } from "@caspeco/casper-ui-library.components.toggle-button";
import { TimeSlot } from "types/time-slot";

interface IActivityTimeSlot {
    time: TimeSlot;
    selectedTime: TimeSlot;
    onClick?: () => void;
}

export default function ActivityTimeSlot({ time, selectedTime, onClick }: IActivityTimeSlot) {
    const isSelected =
        time.ruleId === selectedTime?.ruleId && time.start.isSame(selectedTime?.start);

    return (
        <ToggleButton
            key={time.start.format("HH:mm")}
            className="timeButton"
            onClick={onClick}
            isPressed={isSelected}
            w="full"
            px={ThemeSpaceVariable.Small}
            size="lg"
            h="48px"
        >
            {time.start.format("HH:mm")}
        </ToggleButton>
    );
}
