import { useAvailableTimes } from "api/api-hooks/use-available-times";
import { useWebBooking } from "api/api-hooks/use-web-booking";
import { useMemo } from "react";
import { useBookingStateContext } from "hooks/use-booking-state";
import { AvailableTimesUnit, AvailableTimesSection } from "types/available-times";
import { WebRule } from "types/web-rule";

export const useSelectedRule = (ruleIdOverride?: number): WebRule | undefined => {
    const state = useBookingStateContext();
    const availableTimes = useAvailableTimes(state.date);
    const { data: webBooking } = useWebBooking();

    const ruleId = (state.times && state.times[0]?.ruleId) || ruleIdOverride;

    let selectedRule = useMemo(() => {
        return availableTimes?.data
            ?.flatMap((item: AvailableTimesUnit) =>
                item.sections.flatMap((section: AvailableTimesSection) => section.timeSets)
            )
            .find((timeSet: WebRule) => timeSet.id === ruleId);
    }, [availableTimes?.data, ruleId]);

    if (webBooking?.webBookingRules) selectedRule = webBooking.webBookingRules.at(0);

    return selectedRule;
};
