import {
    ThemeFontSizeVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
import { Text } from "@caspeco/casper-ui-library.components.text";
import { useWebBooking } from "api/api-hooks/use-web-booking";
import StatusSuccess from "components/booking-status/status-icon/status-success";
import { useTranslation } from "react-i18next";

interface CancelBookingSuccessProps {
    isWaitList: boolean;
}

export function CancelBookingSuccess({ isWaitList }: CancelBookingSuccessProps) {
    const { t } = useTranslation();
    const { data: webBooking } = useWebBooking();

    const guests = webBooking?.guests;
    const date = webBooking?.start.format("dddd D MMMM");
    const startTime = webBooking?.start.format("HH:mm");
    const endTime = webBooking?.end.format("HH:mm");
    return (
        <Stack alignItems="center" spacing={ThemeSpaceVariable.Medium}>
            <StatusSuccess />
            <Stack alignItems="center" spacing={ThemeSpaceVariable.Small}>
                <Heading as="h1" maxW="340" fontWeight="500" textAlign="center">
                    {isWaitList ? t("waitList.waitListCanceledTitle") : t("bookingCanceledTitle")}
                </Heading>
                <Text maxW="496" textAlign="center" fontSize={ThemeFontSizeVariable.Large}>
                    {isWaitList
                        ? t("waitList.waitListCanceledMessage")
                        : t("bookingCancelSuccessMessage", { guests, date, startTime, endTime })}
                </Text>
            </Stack>
        </Stack>
    );
}
