import {
    Accordion,
    AccordionItem,
    AccordionPanel,
} from "@caspeco/casper-ui-library.components.accordion";
import ActivityTimeSlot from "components/selection-components/select-activity/activity-time-slot";
import TimeSlotGrid from "components/time-slot-grid";
import { TimeSlot } from "types/time-slot";
import { WebRule } from "types/web-rule";

interface ITimeSetAccordion {
    timeSet: WebRule;
    selectedTime: TimeSlot;
    onClick?: (time: TimeSlot) => void;
}

const TimeSetAccordion = ({ timeSet, selectedTime, onClick }: ITimeSetAccordion) => {
    return (
        <Accordion w="full" variant="noBorder">
            <AccordionItem title={timeSet.title}>
                <AccordionPanel>
                    <TimeSlotGrid
                        items={timeSet.times.map((time) => (
                            <ActivityTimeSlot
                                key={time.start.format("HH:mm")}
                                time={time}
                                selectedTime={selectedTime}
                                onClick={() => onClick(time)}
                            />
                        ))}
                    />
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );
};

export default TimeSetAccordion;
