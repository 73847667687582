import { useExternalBookingSettings } from "api/api-hooks/use-external-booking-settings";
import { useBookingStateContext } from "hooks/use-booking-state";
import { State } from "store/store-types";
import { UnitMetaDataExternal } from "types/external-booking-settings";

export enum ActiveStep {
    Guests,
    GuestsChildren,
    Date,
    Times,
    Details,
}

function getActiveStep(state: State, unitMetaData: UnitMetaDataExternal) {
    const { maxWebGuests, activateWebChildren } = unitMetaData;
    const { guests, guestsChildren, date, times } = state;
    const tooManyGuests = maxWebGuests && guests > maxWebGuests;

    if (!guests || tooManyGuests) {
        return ActiveStep.Guests;
    }
    if (activateWebChildren && guestsChildren === null) {
        return ActiveStep.GuestsChildren;
    }
    if (!date) {
        return ActiveStep.Date;
    }
    if (!times) {
        return ActiveStep.Times;
    }
    return ActiveStep.Details;
}

export const useActiveStep = () => {
    const unitsMetaData = useExternalBookingSettings().data.unitsMetaData;
    const state = useBookingStateContext();

    return getActiveStep(state, unitsMetaData);
};
