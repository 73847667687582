import { resources, supportedLanguages } from "config/resources";
import i18n, { FormatFunction } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import moment from "moment";
import { initReactI18next } from "react-i18next";
import { getCurrency, setCurrencyDisplay } from "translations/currency";
import { getSearchParams } from "search-params";

// Import all moment locales
import "moment/dist/locale/da"; // Danish
import "moment/dist/locale/es"; // Spanish
// import "moment/dist/locale/et"; // Estonian
import "moment/dist/locale/fi"; // Finnish
// import "moment/dist/locale/fr"; // French
import "moment/dist/locale/nb"; // Norwegian
// import "moment/dist/locale/nl"; // Dutch
// import "moment/dist/locale/ru"; // Russian
import "moment/dist/locale/sv"; // Swedish

const { system } = getSearchParams();
const currency = getCurrency(system);
const userLocale = navigator.language;

interface CustomFormatParams {
    display?: "symbol" | "narrowSymbol" | "none" | "name" | "code"; // other possible values can be added
}
/**
 * Runs when accessing a translation key that implements string interpolation
 */
const customFormat: FormatFunction = (value: unknown, format, _, options) => {
    const params = options as CustomFormatParams;
    /* Check which format parameter that is passed to the function */
    if (format === "currency") {
        /* If the format is set to currency - format the value with the Intl.NumberFormat based on the users locale */
        const formattedCurrency = new Intl.NumberFormat(userLocale, {
            style: "currency",
            currency: currency,
            currencyDisplay: setCurrencyDisplay(params.display),
        }).format(Number(value));
        /* If the display is none - trim of the last 3 ISO letters */
        if (params.display === "none") {
            return formattedCurrency.replace(/[a-zA-Z]/g, "").trim();
        }
        /* return formated value */
        return formattedCurrency;
    }
    return value as string;
};

void i18n
    .use(initReactI18next)
    .use(HttpApi)
    .use(LanguageDetector)
    .init({
        detection: {
            order: ["querystring", "localStorage", "navigator"],
            lookupQuerystring: "lang",
        },
        nonExplicitSupportedLngs: true,
        supportedLngs: supportedLanguages,
        fallbackLng: ["en", "sv"],
        debug: false,
        interpolation: {
            formatSeparator: ",",
            format: customFormat,
            escapeValue: false,
        },
        resources,
    })
    .then(() => {
        moment.locale(i18n.language);
    });

// Listen for changes in i18n language and update moment's locale accordingly
i18n.on("languageChanged", function (lng) {
    moment.locale(lng);
});
